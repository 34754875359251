
.dialog-headline {
  display: flex;
  align-items: center; /* Vertically center the items */
  justify-content: space-between; /* Place the title on the left and the icon on the right */
  font-weight: 500;
  margin: -1.5em -1.5em 0 -1.5em;
  padding: 1em;
  font-size: 16px;
  background-color: #0C7BB8;
  color: #FFFFFF;
  height: 43px; /* Set a fixed height if necessary */
  box-sizing:border-box; /* Ensure padding is included in height */
  border-radius: 5px 5px 0px 0px;
}

.headline-title {
  flex-grow: 1; /* Allows the title to take up remaining space */
}

.close-icon {
  width: 21px;
  height: 21px;
  cursor: pointer;
}
